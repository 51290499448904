import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { backendRoutes, frontendRoutes } from '../../../../Constants/constants';
import Loader from '../../../Loader/Loader';
import fingerprint from '../../../../assets/images/icn-fingerprint.svg';
import { Button } from '../../../Button/Button';
import LockIcon from '../../../../assets/images/icn-lock-open.svg';
import { Spinner } from 'react-bootstrap';

type LockInstructions = {
  lockCode: string;
  step1Text: string;
  step2Text: string;
  manufacturer: string;
  tourHours: {
    tourHoursWindow: string;
    isOutsideTourHours: boolean;
    isToursAvailable: boolean;
  }
};

const LockScreen = () => {
  const userDetails = useSelector((state: any) => state.register.register);
  const property_details = useSelector((state: any) => state.property.property);
  const property_id = property_details && property_details.id;
  const user_token = localStorage.getItem('user_token');

  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [isCheckingLockDetails, setIsCheckingLockDetails] = useState(false);
  const [isLocked, setIslocked] = useState(true);
  const [seconds, setSeconds] = useState(1);
  const [lockInstructions, setLockInstructions] = useState<LockInstructions>({
    lockCode: '',
    step1Text: '',
    step2Text: '',
    manufacturer: '',
    tourHours: {
      tourHoursWindow: "",
      isOutsideTourHours: false,
      isToursAvailable: true
    }
  });

  let params = useParams<{ builder_name?: string }>();

  const history = useHistory();
  const previousPath = () => {
    history.goBack();
  };

  useEffect(() => {
    const get_property_code = () => {
      setIsLoading(true);
      axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `properties/${property_id}/webflow-code`,
        method: 'GET',
        headers: {
          'x-auth-token': user_token,
        },
      })
        .then(res => {
          const { code: lockCode, step1Text, step2Text, manufacturer, visitId, tourHours } = res.data;
          if (res.status === 200) {
            setIsLoading(false);
            setLockInstructions({ lockCode, step1Text, step2Text, manufacturer, tourHours });
            localStorage.setItem('lock_code', lockCode);
            localStorage.setItem('visit_id', visitId);
            localStorage.setItem('pid', property_id);
          }
        })
        .catch(err => {
          if (err && err.response && err.response.status === 403) {
            setIsLoading(false);
            const msg = err.response.data;
            setShowAlert(true);
            setMessage(msg);
          } else {
            setIsLoading(false);
            setShowAlert(true);
            setMessage('Something Went Wrong Or Network Error');
          }
        });
    };

    get_property_code();
  }, [property_id, user_token]);

  useEffect(() => {
    const checkLockDetails = async () => {
      try {
        setIsCheckingLockDetails(true);

        const { data } = await axios({
          baseURL: backendRoutes.MOBILE_BASE_URL,
          url: `properties/${property_id}/lock-live-details`,
          method: 'GET',
          headers: {
            'x-auth-token': user_token,
          },
        });

        setIsCheckingLockDetails(false);
        setIslocked(data.locked);
      } catch (err) {
        setIsCheckingLockDetails(false);
        setIsUnlocking(false);
        setShowAlert(true);

        if (err instanceof Error) {
          const msg = err.message;
          setMessage(msg);
          return;
        }

        console.log('Unexpected error', err);
      }
    };

    if (property_details?.smart_lock_serial_number && !isLoading) {
      setIsUnlocking(true);

      const timer = setTimeout(() => {
        if (seconds < 15) {
          setSeconds(seconds => seconds + 1);
        }

        if (!isCheckingLockDetails) {
          checkLockDetails();
        }
      }, 1000);

      if ((!isLocked || seconds >= 15) && !isCheckingLockDetails) {
        setIsUnlocking(false);
        clearInterval(timer);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    isCheckingLockDetails,
    isLoading,
    isLocked,
    property_details?.smart_lock_serial_number,
    property_id,
    seconds,
    user_token,
  ]);

  return (
    <section className="main-section success-screen tour-success-screen d-flex justify-content-center">
      {(!lockInstructions.tourHours.isOutsideTourHours && lockInstructions.tourHours.isToursAvailable) && (<div className="box">
        <div className="status-title success">
          <h1>SUCCESS!</h1>
          <span>
            Hi {userDetails?.firstName}! You are all set to tour:{' '}
            {property_details?.marketingName ? `${property_details?.marketingName}, ` : ''}
            {property_details?.address ?? ''}
          </span>
          <div>
            <span onClick={previousPath} className="a">
              Back
            </span>
            {/* {params.builder_name !== noBuilderTour.TOURS && <> */}
              <Link
                to={frontendRoutes.selectTourDate(params.builder_name, property_id).path}
                className=""
              >
                Reschedule
              </Link>
              <Link to={frontendRoutes.chooseCommunity(params.builder_name).path} className="">
                Start Over
              </Link>
            {/* </>} */}
          </div>
        </div>
        <div className="box-data d-flex flex-column text-center">
          <div className="my-auto">
            {!property_details?.smart_lock_serial_number && (
              <>
                {showAlert === false ? (
                  <>
                    <p className="lock-instructions">{lockInstructions?.step1Text}</p>
                    <p className="lock-instructions">{lockInstructions?.step2Text}</p>
                  </>
                ) : (
                  <h2>{message}</h2>
                )}

                {lockInstructions?.manufacturer === 'lockly' && (
                  <>
                    <label className="lock-code">
                      {lockInstructions?.lockCode}
                      <img width="20" src={fingerprint} alt="" />
                    </label>
                    <span>then</span>
                    <label className="lock-code">
                      {lockInstructions?.lockCode}
                      <img width="20" src={fingerprint} alt="" />
                    </label>
                  </>
                )}

                <h2 className="h1">Your lock code is:</h2>
                <label className="lock-code">
                  {lockInstructions?.lockCode}
                  {lockInstructions?.manufacturer === 'igloohome' && (
                    <img src={LockIcon} alt="lock" />
                  )}
                </label>
              </>
            )}
            {property_details?.smart_lock_serial_number && (
              <>
                {!isLoading && isUnlocking && (
                  <>
                    <p className="h1" style={{ marginBottom: '40px' }}>
                      Unlocking Door... Please stand by
                    </p>
                    <Spinner animation="border" style={{ marginBottom: '40px' }} />
                  </>
                )}
                {!isUnlocking && (
                  <>
                    <h2 className="h1" style={{ fontSize: '40px', lineHeight: '48px' }}>
                      {`${
                        isLocked
                          ? 'Unable to Unlock. Please try the code'
                          : 'Door Unlocked. Please enjoy your visit'
                      }`}
                    </h2>
                    <p className="h1" style={{ margin: '20px 0' }}>
                      ...
                    </p>
                    <p className="h1">
                      Backup code below is valid for 10 minutes. Please use it if the door did not
                      unlock automatically.
                    </p>
                    <label className="lock-code">{lockInstructions?.lockCode}</label>
                  </>
                )}
              </>
            )}
            <span>
              Unable to unlock?{' '}
              <a href="tel:+1-844-531-1283" className="" target="_blank" rel="noreferrer">
                Help <i className="icn icn-phone"></i>
              </a>
            </span>
          </div>
          {isLoading && (
            <>
              <Loader />
              {property_details?.smart_lock_serial_number && (
                <p
                  style={{
                    position: 'absolute',
                    bottom: '420px',
                    left: '160px',
                    zIndex: 999,
                    fontSize: '30px',
                    color: '#fff',
                  }}
                >
                  Unlocking...
                </p>
              )}
            </>
          )}
          <div className="box-footer">
            <Link to={frontendRoutes.feedback(params.builder_name).path} className="w-100">
              <Button className="button w-100">I'm in</Button>
            </Link>
            {/* onClick={checkOS} */}
            <p>
              To make the most of the NterNow experience, please download the mobile app. For more
              questions: <Link to="#">FAQ</Link>.
            </p>
          </div>
        </div>
      </div>)}
      {(lockInstructions.tourHours.isOutsideTourHours || !lockInstructions.tourHours.isToursAvailable) && (<div className="box">
        <div className="status-title error">
          <h1>Unable to access!</h1>
          <span>
            Hi {userDetails?.firstName}! You cannot access:{' '}
            {property_details?.marketingName ? `${property_details?.marketingName}, ` : ''}
            {property_details?.address ?? ''} at this time .
          </span>
          <div>
            <span onClick={previousPath} className="a">
              Back
            </span>
          </div>
        </div>
        <div className="box-data d-flex flex-column text-center">
          <div className="my-auto">
            {!lockInstructions.tourHours.isToursAvailable && <div>
              <label className="lock-code">
              Tours are not available this day.
              </label>
              &nbsp;
              &nbsp;
            </div>}
            {lockInstructions.tourHours.isOutsideTourHours && <div>
              <label className="lock-code">
                Outside tour hours!
              </label>
              <h2 className="h1">Tours are only available during these hours:</h2>
              &nbsp;
              <h2 className="h1">{lockInstructions.tourHours.tourHoursWindow}</h2>
              &nbsp;
            </div>}
            <span>
              Unable to unlock?{' '}
              <a href="tel:+1-844-531-1283" className="" target="_blank" rel="noreferrer">
                Help <i className="icn icn-phone"></i>
              </a>
            </span>
          </div>

          <div className="box-footer">
            <p>
              To make the most of the NterNow experience, please download the mobile app. For more
              questions: <Link to="#">FAQ</Link>.
            </p>
          </div>
        </div>
      </div>)}
      
    </section>
  );
};

export default LockScreen;
