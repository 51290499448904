import { useEffect, useState } from 'react';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Descope } from '@descope/react-sdk';
import { getSessionToken } from '@descope/react-sdk';

import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import { setTimeout } from 'timers';
import { emailValidation, registerValidation } from '../../../Constants/validations';
import Loader from '../../Loader/Loader';
import { getBuilderId, getUserInfo, userDetail } from '../../../redux/action/action';
import { isToLogin, clearSetLogin } from './../../../utils';
import { Button } from '../../Button/Button';
import Header from '../../Header/Header';
import { BuilderSettings } from '../../../redux/types/builders';
import jwt_decode from 'jwt-decode';

interface tokenPayload {
  customerId: number;
  username: string;
  iat: number;
  exp: number;
}

const EmailVerify = () => {
  const params = useParams<{ builder_name?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [editModeEmail, setEditModeEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [didSendTempPassword, setDidSendTempPassword] = useState(false);
  const [didNotSetEmail, setDidNotSetEmail] = useState(false);
  const [passwordlessLogin, setPasswordlessLogin] = useState(false);

  const register = useSelector((state: any) => state.register.register);
  const property = useSelector((state: any) => state.property.property);
  const builderId = useSelector((state: any) => state.builder.builder_id);
  const additionalInfo = useSelector((state: any) => state.property.additionalInfo);
  const scheduledVisitId = useSelector((state: any) => state.scheduledVisit.scheduledVisitId);
  const phoneNumber = useSelector((state: any) => state.phNumber.phNumber?.phone);
  const builderSettings: BuilderSettings = useSelector((state: any) => state.builder.settings);
  const tour = useSelector((state: any) => state.tour.tour);

  const token = localStorage.getItem('static_token');
  const builderName = localStorage.getItem('builder_name');

  useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

  const togglePassword = () => {
    setShowPassword(showPassword ? false : true);
  };
  const editEmail = () => {
    setEditModeEmail(true);
  };

  const verify_email = (formData: any) => {
    setIsLoading(true);
    axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: backendRoutes.auth_customer,
      method: 'POST',
      headers: {
        'x-auth-token': token,
      },
      data: formData,
    })
      .then(res => {
        setShowAlert(false);
        if (res.status === 201) {
          setIsLoading(false);
          const userToken = res.data.token;
          localStorage.setItem('user_token', userToken);
          const customer: tokenPayload = jwt_decode(userToken);
          dispatch(getUserInfo(customer?.customerId));
          clearSetLogin();

          if (scheduledVisitId) {
            history.push(
              `${
                frontendRoutes.cancelTour(params.builder_name).path
              }?scheduledVisitId=${scheduledVisitId}`,
            );
            return;
          }

          if (tour) {
            const isDemoMode = property?.builder.toLowerCase() === 'demo mode';

            isDemoMode
              ? history.push(frontendRoutes.LockCodeScreen(params.builder_name).path)
              : history.push(frontendRoutes.shareLocation(params.builder_name).path);
            return;
          }
      
          history.push(frontendRoutes.preferTour(params.builder_name).path);
        }
      })
      .catch(err => {
        if (err && err.response && err.response.status === 403) {
          setIsLoading(false);
          const msg = err.response.data;
          setShowAlert(true);
          setMessage(msg);
        } else {
          setIsLoading(false);
          setShowAlert(true);
          setMessage('Something went wrong Or Network err !!!');
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      });
  };

  const passwordless_login = async (data: any) => {
    try {
      const customer = {
        firstName: register?.firstName,
        lastName: register?.lastName,
        zipCode: register?.zipCode,
        street: register?.streetAddress,
        phoneNumber: register?.phoneNumber,
        isAgent: parseInt(register?.isAgent),
        email: register?.email,
        propertyId: property?.id,
        visitorStatusId: 154,
        visitorContactStatusId: register?.policy === 'decline' ? 157 : 156,
      };

      setIsLoading(true);

      const res = await axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: 'auth/passwordless',
        method: 'POST',
        headers: {
          'x-auth-token': token,
        },
        data: isToLogin() ? data : { ...customer, sessionToken: data.sessionToken },
      });

      setShowAlert(false);

      if (res.status === 201) {
        setIsLoading(false);
        const userToken = res.data.token;
        localStorage.setItem('user_token', userToken);
        const customer: tokenPayload = jwt_decode(userToken);
        dispatch(getUserInfo(customer?.customerId));
        localStorage.removeItem('fields');
        clearSetLogin();
        history.push(frontendRoutes.preferTour(params.builder_name).path);
      }
    } catch (err: any) {
      if (err && err.response && err.response.status === 403) {
        setIsLoading(false);
        setShowAlert(true);
        setMessage(err);
      } else {
        setIsLoading(false);
        setShowAlert(true);
        setMessage('Something went wrong');
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }
    }
  };

  const sendTempPassword = () => {
    if (!formik.values.email) {
      setDidNotSetEmail(true);
      return;
    }
    setDidNotSetEmail(false);
    setIsLoading(true);
    axios({
      baseURL: backendRoutes.MOBILE_BASE_URL,
      url: backendRoutes.send_temp_password,
      method: 'post',
      headers: {
        'x-auth-token': token,
      },
      data: {
        email: formik.values.email,
      },
    })
      .then(res => {
        if (res.status === 201) {
          setIsLoading(false);
          setDidSendTempPassword(true);
          //
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handlePasswordlessLogin = () => {
    setPasswordlessLogin(!passwordlessLogin);
  };

  const formik = useFormik({
    initialValues: {
      email: register?.email ? register?.email : '',
      password: '',
      policy: '',
    },
    enableReinitialize: true,
    validationSchema: !isToLogin() ? registerValidation : emailValidation,
    onSubmit: values => {
      if (!isToLogin()) {
        const data = {
          firstName: register?.firstName,
          lastName: register?.lastName,
          street: register?.streetAddress,
          zipCode: register?.zipCode,
          phoneNumber: register?.phoneNumber,
          isAgent: parseInt(register?.isAgent),
          email: values.email,
          password: values.password,
          propertyId: property?.id,
          visitorStatusId: 154,
          visitorContactStatusId: values.policy === 'decline' ? 157 : 156,
        };
        dispatch(
          userDetail(data, history, frontendRoutes.surveyQuestions(params.builder_name).path),
        );
        localStorage.removeItem('fields');
        const userToken = localStorage.getItem('user_token');
        if (userToken) {
          const customer: tokenPayload = jwt_decode(userToken);
          dispatch(getUserInfo(customer?.customerId));
        }
      } else {
        const data = {
          email: values.email,
          password: values.password,
        };
        verify_email(data);
      }
    },
  });

  useEffect(() => {
    if (!passwordlessLogin) {
      return;
    }

    const descopeEl = document.querySelector('descope-wc');
    const shadowInput = descopeEl?.shadowRoot?.querySelector('input');
    const shadowSelect = descopeEl?.shadowRoot?.querySelector('select');
    const sheet = new CSSStyleSheet();
    //@ts-ignore
    sheet.replaceSync(
      `.btn-primary:not(.btn-link) { background-color: ${builderSettings.primary_color}; border-color: ${builderSettings.primary_color}; color: #fff; } .btn-link { color: ${builderSettings.primary_color} }`,
    );
    //@ts-ignore
    descopeEl?.shadowRoot?.adoptedStyleSheets.push(sheet);

    if (shadowInput && shadowSelect) {
      shadowInput.value = phoneNumber.split('-').join('');
      shadowInput.disabled = true;
      shadowSelect.disabled = true;
      shadowInput.dispatchEvent(new Event('input'));
    }
  });

  return (
    <>
      <section className="main-section varification-screen d-flex justify-content-center">
        <div className="box">
          <Header />
          <span
            onClick={() => (passwordlessLogin ? setPasswordlessLogin(false) : history.goBack())}
            className="link-back icn icn-back-arrow"
          ></span>{' '}
          {!isToLogin() ? (
            <div className="box-head">
              <h1>
                <i className="icn icn-thumb"></i>Nice to Meet You!
              </h1>
              <h2>Let’s make this permanent:</h2>
              <span>
                <sup>*</sup>required
              </span>
            </div>
          ) : (
            <div className="box-head">
              {!passwordlessLogin && (
                <>
                  <h1>
                    <i className="icn icn-thumb"></i>Nice to See You Again!
                  </h1>
                  <h2>Please Login Below</h2>
                  <span>
                    <sup>*</sup>required
                  </span>
                </>
              )}
            </div>
          )}
          <div className="box-data d-flex flex-column">
            <div className={`${!passwordlessLogin && 'visually-hidden'}`}>
              <Descope
                flowId="sign-up-or-in"
                onSuccess={async e => {
                  const sessionToken = getSessionToken();

                  await passwordless_login({ ...e.detail.user, sessionToken });

                  localStorage.removeItem('SET_TO_LOGIN');
                  history.push(frontendRoutes.preferTour(params.builder_name).path);
                }}
                onError={e => console.log(`Could not log in! Error: ${e}`)}
              />
            </div>
            {!passwordlessLogin && (
              <Form
                className="material-form d-flex flex-column h-100"
                onSubmit={formik.handleSubmit}
              >
                <FormikProvider value={formik}>
                  {editModeEmail === false && !isToLogin() ? (
                    <label className="edit-link">
                      Hello, {formik.values.email}{' '}
                      <i className="icn icn-edit" onClick={editEmail}></i>
                    </label>
                  ) : (
                    <Form.Group
                      className={
                        emailFocused === false && formik.values.email === ''
                          ? 'form-group'
                          : 'form-group input-focus'
                      }
                      controlId="email"
                    >
                      <Form.Control
                        type="text"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        isInvalid={formik.touched.email && !!formik.errors.email}
                        onFocus={() => setEmailFocused(true)}
                        onBlur={e => {
                          setEmailFocused(false);
                          formik.handleBlur(e);
                        }}
                      />
                      <Form.Label>Email</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  <Form.Group
                    className={
                      passwordFocused === false && formik.values.email === ''
                        ? 'form-group'
                        : 'form-group input-focus'
                    }
                    controlId="password"
                  >
                    <i
                      className={showPassword ? 'icn icn-eye active' : 'icn icn-eye'}
                      onClick={togglePassword}
                    ></i>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      isInvalid={formik.touched.password && !!formik.errors.password}
                      onFocus={() => setPasswordFocused(true)}
                      onBlur={e => {
                        setPasswordFocused(false);
                        formik.handleBlur(e);
                      }}
                    />
                    <Form.Label>Password</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span className="invalid-feedback d-block ">{showAlert ? message : ' '}</span>

                  {Boolean(additionalInfo?.requires_opt_in) && (
                    <div className={!isToLogin() ? '' : 'd-none'}>
                      <div className="note">
                        <small>
                          {additionalInfo?.opt_in_notice}
                          <sup>*</sup>
                        </small>
                      </div>
                      <Form.Group
                        className={
                          formik.errors.policy
                            ? 'form-group form-group-radio mt-0 is-invalid'
                            : 'form-group form-group-radio mt-0'
                        }
                      >
                        <Form.Check
                          type="radio"
                          label={`I have read and decline the ${builderName}'s communication policy.`}
                          name="policy"
                          id="radio-accept"
                          onChange={formik.handleChange}
                          isInvalid={formik.touched.policy && !!formik.errors.policy}
                          value="accepted"
                          onBlur={e => {
                            formik.handleBlur(e);
                          }}
                        />
                        <Form.Check
                          type="radio"
                          label={`I have read and decline the ${builderName}'s communication policy.`}
                          name="policy"
                          id="radio-decline"
                          onChange={formik.handleChange}
                          isInvalid={formik.touched.policy && !!formik.errors.policy}
                          value="decline"
                          onBlur={e => formik.handleBlur(e)}
                        />
                        <span className="invalid-feedback">
                          {formik.errors.policy ? 'Required' : ''}
                        </span>
                      </Form.Group>
                    </div>
                  )}

                  {isToLogin() && (
                    <>
                      <span className="resend-link">
                        Forgot your password?{' '}
                        <span className="a" onClick={sendTempPassword}>
                          Send temporary password.
                        </span>
                      </span>
                    </>
                  )}
                  {params.builder_name?.toLowerCase() === 'olthof homes' && (
                    <span
                      className="a"
                      style={{ marginTop: '10px' }}
                      onClick={handlePasswordlessLogin}
                    >
                      Passwordless login
                    </span>
                  )}
                  {didNotSetEmail && (
                    <span className="no-email-error">Please Enter your email address.</span>
                  )}
                  {didSendTempPassword && (
                    <span className="temp-password-success">
                      A temporary password was sent to your email. It is valid for the next 15
                      minutes.
                    </span>
                  )}

                  {isLoading && <Loader />}
                  <div className="box-footer mt-auto">
                    <Button className="button w-100" type="submit">
                      {!isToLogin() ? 'Register' : 'Login'}
                    </Button>
                  </div>
                </FormikProvider>
              </Form>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailVerify;
