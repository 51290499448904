import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs'

import { message_token } from '../../../Constants/api';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import { phoneValidationSchema } from '../../../Constants/validations';
import Loader from '../../Loader/Loader';
import InputMask from 'react-input-mask';
import { Button } from '../../Button/Button';
import { getBuilderId, getPhoneNumber } from '../../../redux/action/action';

const PhoneVerification = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const builderId = useSelector((state: any) => state.builder.builder_id);
	const previousPath = () => {
		history.goBack();
	}
	let params = useParams<{ builder_name?: string }>();
	// const [showAlert, setShowAlert] = useState(false);
	// const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false)
	const phoneNumber = useSelector((state: any) => state.phNumber.phNumber?.phone)

	useEffect(() => {
    if (!builderId) {
      dispatch(getBuilderId());
    }
  }, [dispatch, builderId]);

	useEffect(() => {
		message_token()
	}, [])

	const token = localStorage.getItem("message_token");

	const getOtpCode = (formData: any) => {
		setIsLoading(true);
		axios({
			baseURL: backendRoutes.MESSAGING_BASE_URL,
			url: backendRoutes.get_otp,
			method: "post",
			headers: {
				"x-auth-token": token
			},
			data: formData
		}).then((res) => {
			const secure_token = res.data.secret
			if (res.status === 201) {
				setIsLoading(false);
				localStorage.setItem("secure_token", secure_token)
				history.push(frontendRoutes.otpVerify(params.builder_name).path)
			}

		}).catch((err) => {
			console.log(err);
			// if (err.response.status === 403) {
			// 	setIsLoading(false)
			// 	const msg = err.response.data;
			// 	setShowAlert(true);
			// 	setMessage(msg);
			// 	setTimeout(() => {
			// 		setShowAlert(false)
			// 	}, 2000)
			// }
			// else {
			// 	setIsLoading(false)
			// 	setShowAlert(true);
			// 	setMessage("Something went Wrong or unexpected or Network Error !!!");
			// 	setTimeout(() => {
			// 		setShowAlert(false)
			// 	}, 2000)
			// }
		})
	}

	const formik = useFormik({
		initialValues: {
			phoneNumber: phoneNumber ? phoneNumber : ''
		},
		validationSchema: phoneValidationSchema,
		onSubmit: (values) => {
			const data = {
				phone: values.phoneNumber
			}
			getOtpCode(data)
			dispatch(getPhoneNumber(data));
			
			datadogLogs.logger.info(`Sent 2fa code to phone number: ${values.phoneNumber}`)
		}
	})

	return (
		<section className="main-section phone-screen d-flex justify-content-center">
			<div className="box">
				<div className="main-title">
					<h1>Phone Verification</h1>
					<span><sup>*</sup>required</span>
				</div>
				<span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
				<div className="box-data d-flex flex-column">
					<Form onSubmit={formik.handleSubmit} className="h-100 d-flex flex-column">
						<FormikProvider value={formik}>
							<Form.Group controlId="phone-number">
								<Form.Label>Please enter your phone number<sup>*</sup></Form.Label>
								<InputMask
									mask="999-999-9999"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.phoneNumber}
								>
									{
										() => (
											<Form.Control
												type="text"
												placeholder="000-000-0000"
												name="phoneNumber"
												isInvalid={!!formik.errors.phoneNumber}
											/>
										)}</InputMask>
								{/* disabled={phoneNumber ? true : false} */}
								<Form.Control.Feedback type="invalid">
									{formik.errors.phoneNumber}
								</Form.Control.Feedback>
							</Form.Group>
						</FormikProvider>
						{formik.errors.phoneNumber && <div className='links-section d-flex justify-content-end gap-2 mt-2'>
							<a href="tel:+1-844-531-1283" className="d-flex align-items-center gap-1" target="_blank" rel="noreferrer">
								Need Help? 
								<i className="icn icn-phone"></i>
							</a>
							or
							<Link to={frontendRoutes.signIn(params.builder_name).path}>Login</Link>
						</div> }
						{isLoading && <Loader />}
						<div className="box-footer mt-auto">
              <Button type="submit" className="button w-100">
                Next
              </Button>
            </div>
					</Form>
				</div>
			</div>
		</section>
	)
}

export default PhoneVerification;
