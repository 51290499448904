import { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
// import { Button } from '../Button/Button'
import clock from '../../assets/images/icn-clock.svg';
import calendar from '../../assets/images/icn-calendar.svg';
import bath from '../../assets/images/icn-bath.svg';
import bed from '../../assets/images/icn-bad.svg';
import roomSize from '../../assets/images/icn-room_size.svg';
import location from '../../assets/images/icn-location.svg';
import imageIcon from '../../assets/images/photo-icon.png';
import { scheduledToursResponse } from '../LandingPage/Tours/UpcomingTours';
import imagePlaceholder from '../../assets/images/community-image-1.jpg';
import { useSelector } from 'react-redux'

interface TourCardProps {
  children?: any;
  tour: scheduledToursResponse;
}

const TourCard = ({ children, tour }: TourCardProps) => {
  // const { address_1 } = tour
  const [images, setImages] = useState<any>([]);
  useEffect(() => {
    if (tour?.images?.length > 0) {
      const result = tour.images.map((original: string) => {
        return {
          original: `//images.weserv.nl?url=${original}&w=380&output=webp&il`,
          originalWidth: '380px',
          originalHeight: '380px',
        };
      });
      setImages(result);
    }
  }, [tour.images]);

    const isRental = useSelector((state: any) => state.builder.isRental);
    const price = new Intl.NumberFormat('en-US').format(tour.price)

  return (
    <div className="tour-card">
      {images.length > 0 ? (
        <ReactImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          additionalClass="image-gallery-custom"
          onErrorImageURL={imageIcon}
        />
      ) : (
        <img src={imagePlaceholder} alt="placeholder" style={{ padding: '0.5rem' }} />
      )}
      <div className="tour-card-body p-3">
        <h4 className="text-blue text-center">{tour.address_1}</h4>
        <div className="schedule-box">
          <div className="date-box img-text-box">
            <img src={calendar} alt="" />
            <p>{new Date(tour.scheduled_on).toLocaleDateString()}</p>
          </div>
          <div className="time-box img-text-box">
            <img src={clock} alt="" />
            <p>{new Date(tour.scheduled_on).toLocaleTimeString()}</p>
          </div>
        </div>
        {/* <h4 className='text-blue'>Lark</h4>
            <p className='text-blue mb-0'>AHome Site 4, From the Upper </p> */}
        <p className="my-2">${price}{isRental ? "/month": ""}</p>
        <div className="room-details d-flex">
          <div className="time-box img-text-box">
            <img src={bed} alt="" />
            <p>{tour.bedrooms}Bd</p>
          </div>
          <div className="time-box img-text-box">
            <img src={bath} alt="" />
            <p>{tour.fullBathrooms}Fba</p>
          </div>
          <div className="time-box img-text-box">
            <img src={bath} alt="" />
            <p>{tour.halfBathrooms}Hba</p>
          </div>
          <div className="time-box img-text-box">
            <img src={roomSize} alt="" />
            <p>{tour.squareFootage} Sqft</p>
          </div>
        </div>
        <div className="img-text-box justify-content-start mt-2">
          <img src={location} alt="" />
          <p>
            {tour.address_1} {tour.city}, {tour.state} {tour.zipcode}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TourCard;
