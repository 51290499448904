import axios from "axios";
import * as Yup from "yup";
import { backendRoutes } from "./constants";

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/[a-zA-Z\-.\- ]$/, "Please enter valid name")
    .min(2, "First Name must have atleast 2 characters")
    .max(20, "First Name must not contain more than 20 characters")
    .required("Required"),
  lastName: Yup.string()
    .matches(/[a-zA-Z\-.\- ]$/, "Please enter valid name")
    .min(2, "Last Name must have atleast 2 characters")
    .max(20, "Last Name must not contain more than 20 characters")
    .required("Required"),
  streetAddress: Yup.string()
    .min(2, "Street Address must have atleast 2 characters")
    .required("Required"),
  zipCode: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .min(10, "Phone Number must have atleast 10 numbers")
    .required("Required")
    .test(
      "phoneNumber_async_validation",
      "Phone Number Validation Error",
      (value: any, { createError }: any) => {
        if (!value || value.length < 9) {
          return createError("Required");
        }
        return axios({
          baseURL: backendRoutes.MOBILE_BASE_URL,
          url: backendRoutes.verify_phone_number,
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("static_token"),
          },
          data: {
            phone: value,
          },
        }).catch((e: any) => {
          if (e.response && e.response.status === 403) {
            const message = e.response && e.response.data;
            return createError({ message });
          } else {
            const message =
              e.response && e.response.data.message[0].constraints.isNotEmpty;
            return createError(message.isNotEmpty);
          }
        });
      }
    ),
  email: Yup.string()
    .email()
    .required("Required")
    .test(
      "email_async_validation",
      "Email Validation Error",
      (value: any, { createError }: any) => {
        if (!value) {
          return createError("Required");
        }

        return axios({
          baseURL: backendRoutes.MOBILE_BASE_URL,
          url: backendRoutes.checkEmail,
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("static_token"),
          },
          data: {
            email: value,
          },
        }).catch((e: any) => {
          if (e.response && e.response.status === 403) {
            const message = e.response && e.response.data;
            return createError({ message });
          } else {
            const message = e.response && e.response.data.message[0];
            if (value === "") {
              return createError(message.constraints.isNotEmpty);
            } else {
              return createError(message.constraints.isEmail);
            }
          }
        });
      }
    ),
  agent: Yup.boolean(),
});

export const phValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(10, "Phone Number must have atleast 10 numbers"),
});
export const phoneValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(10, "Phone Number must have atleast 10 numbers")
    .required("Required")
    .test(
      "phoneNumber_async_validation",
      "Phone Number Validation Error",
      (value: any, { createError }: any) => {
        if (!value || value.length < 9) {
          return createError("Required");
        }
        return axios({
          baseURL: backendRoutes.MOBILE_BASE_URL,
          url: backendRoutes.verify_phone_number,
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("static_token"),
          },
          data: {
            phone: value,
          },
        }).catch((e: any) => {
          if (e.response && e.response.status === 403) {
            const message = e.response && e.response.data;
            return createError({ message });
          } else {
            const message =
              e.response && e.response.data.message[0].constraints.isNotEmpty;
            return createError(message.isNotEmpty);
          }
        });
      }
    ),
});
export const tourDateValidation = Yup.object().shape({
  showDate: Yup.string().required("Required"),
  radioTime: Yup.string().required("Required"),
});

export const registerValidation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .min(8, "Password must have atleast 8 characters")
    .max(15, "Password should not contain more than 15 characters")
    .required("Required"),
});

export const emailValidation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required"),
});

export const chooseValidation = Yup.object().shape({
  choose: Yup.object()
    .shape({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    })
    .required("Required")
    .nullable(),
  privacyPolicy: Yup.boolean().oneOf([true], "Required"),
  customDisclosure: Yup.boolean().when('customDisclosureActive', {
    is: true,
    then: Yup.boolean().oneOf([true], "Required"),
  }),
});

export const updateProfileValidation = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
    
  currentPassword: Yup.string().when('newPassword', {
    is: (newPassword: string) => newPassword && newPassword.length > 0,
    then: Yup.string().required('required'),
  }),
  newPassword: Yup.string().when('currentPassword', {
    is: (currentPassword: string) => currentPassword && currentPassword.length > 0,
    then: Yup.string().required('required').min(8, "Password must have atleast 8 characters")
    .max(15, "Password should not contain more than 15 characters"),
  }),
  confirmNewPassword: Yup.string().when('currentPassword', {
    is: (currentPassword: string) => currentPassword && currentPassword.length > 0,
    then: Yup.string().required('required').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  }),
}, [[ 'currentPassword', 'newPassword' ]]);
