import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from '@descope/react-sdk';
import { BrowserRouter as Router } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppStore from './redux/store';

const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '';

datadogLogs.init({
  clientToken,
  service: 'webflow',
  sessionSampleRate: 100,
  env: process.env.REACT_APP_NODE_ENV,
});

const { store /* , persistor */ } = AppStore();
ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <AuthProvider projectId={'P2RpvqnhgCvn3arfIfFA4PaY41lc'}>
      <Router>
        <App />
      </Router>
    </AuthProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
